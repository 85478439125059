import camelcaseKeys from 'camelcase-keys';
import cache from 'lscache';

import { type Price, type ApiMasterPrice } from './prices.type';
import { buildUrl, buildHeaders, buildRequest, fetchRequest } from '../utils/apis/RestApi';
import store from '../store/store';
import translate from '../utils/i18n/SwappingIntlProvider';
import { cacheErrorLog, createLog } from './logger';

const priceStorageKey = 'price';
const actualDate = new Date();
const logger = createLog('api/masterprice');

export const getPrices = (articleIds: number[]): Promise<Price[]> => {
  const state = store.getState();
  const { id, displayPriceWithoutTaxes } = state.userInfo.selectedSite;
  const { realizationSite, creationSite } = state.intervention;
  const storeId = realizationSite && creationSite && realizationSite.id !== creationSite.id ? creationSite.id : id;
  const resourcePath = `retail/prices/items?tiers_concat=7_${storeId}_${storeId}&item_codes=${articleIds}`;
  const url = buildUrl('MASTERPRICE', resourcePath);
  const key = `${priceStorageKey}-${articleIds.join(',')}`;
  const storedPrices = cache.get(key);
  if (storedPrices) {
    return Promise.resolve(JSON.parse(storedPrices));
  }
  const headers = buildHeaders('MASTERPRICE', 'Bearer');
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(data => camelcaseKeys<ApiMasterPrice[]>(data, { deep: true }))
    .then(apiPrices => {
      const result = apiPrices.map(apiPrice => {
        const actualPrice = apiPrice.defaultTimeline.prices
          .map(price => ({ ...price, start: new Date(price.start) }))
          .reduce((acc, current) => {
            if (current.start > acc.start && current.start < actualDate) {
              return current;
            }
            return acc;
          });

        return {
          currency: apiPrice.currency,
          value: displayPriceWithoutTaxes ? actualPrice.valueWithoutTaxes : actualPrice.valueWithTaxes,
          priceType: actualPrice.priceType,
          articleId: Number(apiPrice.id.replace('ITEM_', '')),
        };
      });
      try {
        cache.set(key, JSON.stringify(result), 5);
      } catch (error) {
        cacheErrorLog(error);
      }
      return result;
    })
    .catch((e: Error) => {
      logger.log(e, { storeId, articleIds });

      throw translate('drp.masterprice.error');
    });
};

export const SERVICE_GRID_ID = 423;
export const NO_SIZE_GRID_ID = 254;
