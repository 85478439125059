import { type Brand } from './dictionaries.type';
import { type MasterDataArticle, type Article } from './masterdata/masterdata.type';
import { type OrderRaw } from '../pages/SparePartOrders/types';

export enum LevelType {
  processes = 'processes',
  categories = 'categories',
  products = 'products',
  product = 'product',
}

export interface Thumbnail {
  nextLevel: LevelType;
  entities: ILinkEntity[];
}

export interface ILinkProduct extends ILinkEntity {
  brand?: Brand;
  purchaseDate?: Date;
  articles: MasterDataArticle[];
  traceabilityNumber?: string;
  stringTension?: string;
  location?: string | null;
  isFromPurchase?: boolean;
  updatePecProduct?: boolean;
  hasSpareParts?: boolean;
}

export interface ILinkProductRaw extends ILinkEntity {
  brand?: Brand;
  purchaseDate?: string;
  articles: MasterDataArticle[];
  traceabilityNumber?: string;
  stringTension?: string;
  location?: string | null;
  isFromPurchase?: boolean;
  updatePecProduct?: boolean;
  hasSpareParts?: boolean;
}

export interface ILinkProcess {
  id: number;
  name: string;
}

export interface ILinkCategory extends ILinkProcess {
  imageUrl?: string;
}

export interface ILinkEntity {
  id: number;
  label: string;
  imageUrl?: string;
  isCustom?: boolean;
  ranking?: number;
}

export interface ILinkProductResponse {
  product_ids: number[];
}

export interface ILinkEntityResponse {
  id: number;
  label: string;
  image_url?: string;
  model_code: number;
  ranking: number;
}

export interface CompatibilityModel {
  spare_part_id: number;
  compatibility: boolean | undefined;
  hypothesis: boolean | undefined;
}

export interface CompatibilitySparePartNature {
  spare_part_models: CompatibilityModel[];
  spare_part_nature_id: number;
}

export interface ILinkSparePartNature {
  spare_part_models: number[];
  spare_part_nature_id: number;
}

export interface ILinkServicesNature {
  service_ids: number[];
  spare_part_nature_id: number;
}

export interface AssociatedNatureEntity {
  id: number;
  natureIds: number[];
}

export interface ILinkSparePartsAndServicesNature {
  services: AssociatedNatureEntity[];
  spareParts: AssociatedNatureEntity[];
}

export interface Nature {
  natureId: number;
  natureTitle: string;
}

export interface Entity {
  id?: string;
  name: string;
  modelCode: number;
  quantity: number;
  imageUrl?: string;
  articles: Article[];
  related?: boolean;
  cycle?: string;
  unitQuantity?: number;
  process?: ILinkProcess;
  filtered?: boolean;
  compatibility?: boolean;
  hypothesis?: boolean;
  orders?: OrderRaw[];
}

export interface SparePartEntity extends Entity {
  orderNumbers?: number[];
}

export interface InterventionNature extends Nature {
  spareParts: SparePartEntity[];
  services: Entity[];
  selected?: boolean;
}

export interface NatureWithCategory extends InterventionNature {
  categoryId: number;
}

export interface MostUsedService extends Entity {
  process: ILinkProcess;
  natureId: number;
}

export interface ILinkCategoryRaw {
  id: number;
  label: string;
  image_url: string;
  pixl_id: string;
  ranking?: number;
}

export interface ProductInformation {
  process: ILinkProcess;
  category: ILinkCategory;
}

export interface ILinkNatureCategory {
  id: number;
  label: string;
  imageUrl: string;
  natureIds: number[];
}

export interface NatureCategory {
  id: number;
  label: string;
  imageUrl?: string;
  natures: InterventionNature[];
  selected?: boolean;
}

export interface ILinkLocale {
  code: string;
}

export interface IlinkGroupProductNaturesContent {
  id: number;
  label: string;
  imageURL: string;
  groupProductNatureAssociations: GroupProductNatureAssociation[];
}

export interface GroupProductNatureAssociation {
  uuid: string;
  groupProductNatureID: number;
  productNatureID: number;
}

export interface Process {
  id: number;
  label: string;
  pixl_id: string;
  ranking: number;
  image_url: string;
}
