import { type ILinkEntity, type ILinkProduct, LevelType } from '../../apis/ilink.type';
import { type RootState } from '../store';

export const hasKnownProduct = (product: ILinkProduct | ILinkEntity) => product.id !== -1;
export const isCustomProduct = (product: ILinkProduct | ILinkEntity) => Boolean(product.isCustom);

export const hasCatalogKnownProduct = (state: RootState) => hasKnownProduct(state.catalog.search.product);
export const hasCatalogProductOrCustom = ({
  catalog: {
    search: { product },
  },
}: RootState) => hasKnownProduct(product) || isCustomProduct(product);

export const selectCatalog = (state: RootState) => state.catalog;

export const selectCatalogSearch = (state: RootState) => state.catalog.search;

export const selectBreadcrumb = (state: RootState) => state.catalog.search.breadcrumb;

export const selectBreadcrumbCategory = (state: RootState) =>
  state.catalog.search.breadcrumb.find(breadcrumb => breadcrumb.level === LevelType.categories);

export const selectBreadcrumbProducts = (state: RootState) =>
  state.catalog.search.breadcrumb.find(breadcrumb => breadcrumb.level === LevelType.products);

export const selectProduct = (state: RootState) => state.catalog.search.product;

export const selectProductArticles = (state: RootState) => state.catalog.search.product.articles;
