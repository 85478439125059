import { OFFERED_POGO_ID, Pogos, translatePogoId } from '../apis/interventions/intervention.const';
import { type WorkshopContextType } from '../apis/interventions/intervention.type';
import { type Article } from '../apis/masterdata/masterdata.type';
import { isPogoAuthorized } from '../store/selectors/intervention';

export const enum LifeStage {
  creation = 0,
  active = 1,
  new = 2,
  deletionWithStockinCAC = 3,
  deletionWithoutStockAtAll = 4,
  archived = 5,
  deletionWithoutStockInCAC = 7,
  deletionWithoutStockInCAR = 8,
}

export const availableArticleLifeStages = [
  LifeStage.active,
  LifeStage.deletionWithStockinCAC,
  LifeStage.deletionWithoutStockInCAC,
  LifeStage.deletionWithoutStockInCAR,
];

export const iLinkLifeStages = [LifeStage.active, LifeStage.new, LifeStage.deletionWithStockinCAC, LifeStage.deletionWithoutStockInCAC];

export const emptyArticle = (): Article => ({
  articleId: 0,
  gridId: 0,
  labelGrid: '',
  priceType: '',
  stock: 0,
  value: null,
  selected: true,
  gridValueId: '',
  eanNum: '',
});

export const findSelectedArticle = (article: Article) => article.selected;

export const findArticleId = (articleId: number) => (article: { articleId: number }) => article.articleId === articleId;

export const findAuthorizedPogo = (selectedContextType: WorkshopContextType) => (article: Article) =>
  isPogoAuthorized(selectedContextType.authorizedPogos, article.gridValueId);

export const updateSparePartArticles = (articles: Article[], contextType: WorkshopContextType | null) => {
  if (!contextType?.internal) {
    return articles;
  }

  const sparePartInternalPogos = contextType.authorizedPogos.filter(pogo => (pogo.linkedPogoId ?? pogo.pogoId) !== OFFERED_POGO_ID);
  const newGridValueId = translatePogoId(sparePartInternalPogos[0]?.pogoId);
  return articles.map(article =>
    article.selected
      ? {
          ...article,
          free: newGridValueId !== Pogos.PAYANT,
          gridValueId: newGridValueId,
          priceType: newGridValueId,
        }
      : article
  );
};
