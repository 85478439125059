import { useSelector } from 'react-redux';

import { selectIsRegionalWorkshopSelector, selectSelectedSite, selectStoreId } from '../../store/selectors/userInfo';
import { selectLanguage } from '../../store/i18n.selectors';

type RedirectParam = {
  link: string;
  param: string;
};

const useDataStudio = () => {
  const storeId = useSelector(selectStoreId);
  const { shortName } = useSelector(selectSelectedSite);
  const isRegionalWorkshop = useSelector(selectIsRegionalWorkshopSelector);
  const language = useSelector(selectLanguage);

  const isFrenchLanguage = language === 'FR';

  let redirectParam: RedirectParam = isFrenchLanguage ? { link: 'u', param: 'df485' } : { link: 'x', param: 'df537' };

  if (isRegionalWorkshop) {
    redirectParam = isFrenchLanguage ? { link: 'g', param: 'df607' } : { link: 'n', param: 'df644' };
  }

  const baseUrl = `https://datastudio.google.com/u/0/reporting/1ys13kGjbnVEGJjGfX4TurSWrB11Xte0V/page/${redirectParam.link}flx?params=`;

  return encodeURI(`${baseUrl}{"${redirectParam.param}":"include%EE%80%800%EE%80%80IN%EE%80%80${storeId} - ${shortName}"}`);
};

export default useDataStudio;
