import { type ListenerMiddleware } from '@reduxjs/toolkit';
import type { ReduxAjaxParams } from '@chipp972/redux-ajax';
import type { Dispatch } from 'redux';

import { colors, externalToastStore } from '../../utils/toaster/ToastProvider';
import translate from '../../utils/i18n/SwappingIntlProvider';
import { type ConfigState } from '../configSlice';

const SECONDS_BETWEEN_VERSION_CHECK = 600 * 1000;
let lastCheck = Date.now() - (process.env.NODE_ENV === 'test' ? 0 : SECONDS_BETWEEN_VERSION_CHECK);
let currentVersion: string | null = null;
const headers = new Headers();
headers.append('pragma', 'no-cache');
headers.append('cache-control', 'no-cache');

export const appVersionChecker: ListenerMiddleware = () => next => action => {
  const checkedDate = Date.now();
  if (checkedDate - lastCheck > SECONDS_BETWEEN_VERSION_CHECK) {
    lastCheck = Date.now();
    (async () => {
      try {
        const versionResponse: Record<'version', string> = await fetch(new URL('/version.json', location.origin).toString(), { headers }).then(
          response => response.json()
        );

        const newVersion = versionResponse.version;
        if (currentVersion !== null && newVersion !== currentVersion) {
          externalToastStore.addToast(translate('application.new.version'), colors.blueDKT, SECONDS_BETWEEN_VERSION_CHECK, true);
        }
        currentVersion = newVersion;
      } catch (error: any) {
        if (error instanceof Error) {
          externalToastStore.addToast(error.message);
        }
      }
    })();
  }

  next(action);
};

export const addTokenToReduxRequest: ListenerMiddleware<{
  configuration: ConfigState;
}> = store => (next: Dispatch) => (action: { type: string; data: ReduxAjaxParams }) => {
  if (action.type === 'AJAX_REQUEST_SET_REQUEST_PARAMS') {
    const config = store.getState().configuration.config;

    action.data.credentials = action.data.url?.includes(config.services.ICARE_BACK.url) ? 'include' : 'same-origin';
    action.data.headers = {
      ...action.data.headers,
      ...{
        Authorization: `Bearer ${store.getState().configuration.token}`,
      },
    };
  }
  next(action);
};
