import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Case } from '@chipp972/react-case-when';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import cache from 'lscache';
import clsx from 'clsx';

import { logout } from '../../apis/userinfo';
import { useCustomerStore, useI18nStore } from '../../store/hooks';
import { cleanBeforeReload } from '../../utils/redirectToFed';
import useToaster from '../../utils/hooks/useToaster';
import { useAppDispatch } from '../../store/store';
import { selectInterventionDocNumber } from '../../store/selectors/intervention';
import { selectUserDisplayName } from '../../store/selectors/userInfo';
import { resetIntervention } from '../../store/interventionSlice';
import { checkShippingGroup } from '../../business/shippingHelper';
import useClickOutside from '../../utils/hooks/useClickOutside';
import useStringState from '../../utils/hooks/useStringState';
import { selectAuthService } from '../../store/selectors/auth';
import { LoaderContext } from '../../utils/loader/LoaderProvider';
import { type Locale } from '../../store/i18nSlice';
import CountryFlag from '../../utils/CountryFlag';
import Button from '../Buttons/Button';
import StoresDropdown from './StoresDropdown';
import SearchBar from '../SearchBar/SearchBar';
import { RoutesPath, getAppRoute } from '../../core/routes';

import './toolbar-top.scss';

export const ToolbarTop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const docNumber = useSelector(selectInterventionDocNumber);
  const displayName = useSelector(selectUserDisplayName);
  const authService = useSelector(selectAuthService);
  const { deleteCustomer } = useCustomerStore();
  const { appLanguage, availableLanguages } = useI18nStore();
  const [userInputSearch, setUserInputSearch] = useStringState('');
  const [userInfosMenu, deployUserInfosMenu] = useState(false);
  const dispatch = useAppDispatch();
  const [flags, deployFlags] = useState(false);
  const [hasHeader] = useState(location.pathname.startsWith(getAppRoute(RoutesPath.catalogRootPage)));
  const [withSearchBar, setWithSearchBar] = useState(false);
  const refUserInfo = useClickOutside<HTMLDivElement>(deployUserInfosMenu);
  const refFlags = useClickOutside<HTMLDivElement>(deployFlags);
  const { addToast, red } = useToaster();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const languages = availableLanguages.map(({ country, language }) => ({
    language,
    country,
    selected: language === appLanguage?.language && country === appLanguage?.country,
  }));

  useEffect(() => {
    setWithSearchBar(location.pathname !== getAppRoute(RoutesPath.homePage));
  }, [location]);

  const handleLogout = async () => {
    try {
      showLoader();

      await logout();

      const hasCachedKeys = Object.keys(localStorage).some(storageKey => storageKey.includes('lscache'));

      if (hasCachedKeys) {
        cache.flush();
      }

      await authService.signinRedirect();
    } catch (error) {
      addToast(t('error.generic.message'), red);
    } finally {
      hideLoader();
    }
  };

  const handleClickOnFlag = (language: Locale) => {
    showLoader();
    cleanBeforeReload(language, null);
  };

  const handleInterventionSearch = () => {
    if (userInputSearch) {
      if (checkShippingGroup(userInputSearch)) {
        navigate(`/shipping-group/${userInputSearch}`);
        setUserInputSearch('');
        return;
      }
      if (isNaN(parseInt(userInputSearch, 10))) {
        addToast(t('error.message.invalid.intervention.number'), red);
        return;
      }
      dispatch(resetIntervention(docNumber === userInputSearch));
      deleteCustomer();
      navigate(`/intervention/${userInputSearch}`);
      setUserInputSearch('');
    }
  };

  return (
    <div className={clsx('toolbar-top-container', { 'with-search-bar': withSearchBar })}>
      <div>
        <Case when={withSearchBar}>
          <SearchBar
            id="toolbar-top-search"
            setUserInputSearch={setUserInputSearch}
            value={userInputSearch}
            action={handleInterventionSearch}
            placeholderId="toolbar.top.searchbar.placeholder"
            className={clsx('toolbar-searchbar', { white: hasHeader })}
            isWithSpace={false}
          />
        </Case>
      </div>

      <div className="dropdowns">
        <StoresDropdown hasHeader={hasHeader} />
        <div className="toolbar-top-dropdown" ref={refUserInfo}>
          <div className="dropdown-label user-info" onClick={() => deployUserInfosMenu(!userInfosMenu)} data-testid="dropdown-userinfo">
            <div className="user-name">{displayName}</div>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          {userInfosMenu && (
            <div className="popin-userinfo">
              <Button content="toolbar.top.userinfos.button.logout" action={handleLogout} />
            </div>
          )}
        </div>
        {!!languages.length && (
          <div className="toolbar-top-dropdown" ref={refFlags}>
            <div className="dropdown-label flags" onClick={() => deployFlags(!flags)}>
              <CountryFlag countryCode={appLanguage?.country ?? 'EN'} />
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <div className={`popin-flags ${flags ? 'show' : ''}`} data-testid="popin-flags">
              {languages.map((availableLanguage, index) => (
                <div
                  className={clsx('flag-line', { selected: availableLanguage.selected })}
                  key={index}
                  onClick={() => handleClickOnFlag(availableLanguage)}
                >
                  <div>{availableLanguage.selected && <FontAwesomeIcon icon={faCheck} />}</div>
                  <div>{availableLanguage.language}</div>
                  <CountryFlag countryCode={availableLanguage.country} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolbarTop;
