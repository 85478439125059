// @ts-strict-ignore
/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax, import/order */

import store from '../store/store';
import { type Locale } from '../store/i18nSlice';
import { type ReverseMap } from '../types/globals';
import { addHoursTo, getCurrentDate, getDifferenceInMinutes, getEndOfDay, setHoursTo, setMinutesTo } from './date/date-utils';

export const letterRegex = /[a-zA-Z]/g;

export const minutesToMidnight = () => getDifferenceInMinutes({ firstDate: getEndOfDay(getCurrentDate()), secondDate: getCurrentDate() });

export const getFuturePledgeDate = (date: Date) => {
  if (date.getMinutes() > 30) {
    return addHoursTo({ date: setMinutesTo({ date, quantity: 0 }), quantity: 1 });
  }

  return setMinutesTo({ date, quantity: 30 });
};

export const getSameDateWithActualHour = (date: Date) =>
  setHoursTo({ date: setMinutesTo({ date, quantity: getCurrentDate().getMinutes() }), quantity: getCurrentDate().getHours() });

export const blockedExtensions = [
  'dll',
  'exe',
  'app',
  'swf',
  'sys',
  'jar',
  'gzquar',
  'lnk',
  'zix',
  'js',
  'scr',
  'ws',
  'bat',
  'aru',
  'bin',
  'ozd',
  'wmf',
  'com',
  'ocx',
  'class',
  'vbs',
  'drv',
  'chm',
  'shs',
  'pgm',
  'dev',
  'xlm',
  'vba',
  'pif',
  'xnxx',
  'vbe',
  'vxd',
  'tsa',
  'boo',
  'tps',
  'sop',
  'hlp',
  '386',
  'vb',
  'pcx',
  'bkd',
  'rhk',
  'exe1',
  'cih',
  'lik',
  'osa',
  'dyz',
  'dyv',
  'kcd',
  'vbx',
  'wsc',
  '.9',
  's7p',
  'dxz',
  'dlb',
  'php3',
  'dom',
  'mfu',
  'hlw',
  'mjg',
  'rsc_tmp',
  'cla',
  'bup',
  'mjz',
  'bhx',
  'upa',
  'mcq',
  'dli',
  'xir',
  'fnr',
  'xlv',
  'txs',
  'bxz',
  'xdu',
  'cxq',
  'ska',
  'wlpginstall',
  'wsh',
  'cfxxe',
  'tti',
  'qrn',
  'vexe',
  'dllx',
  'fag',
  'xtbl',
  'smtmp',
  'tko',
  'ceo',
  'uzy',
  'oar',
  'scr',
  'bll',
  'plc',
  'ssy',
  'dbd',
  'smm',
  'spam',
  'ce0',
  'zvz',
  'cc',
  'blf',
  'ctbl',
  'iws',
  'vzr',
  'nls',
  'hsq',
  'lkh',
  'aepl',
  'rna',
  'let',
  'hts',
  'aut',
  'delf',
  'buk',
  'fuj',
  'atm',
  'ezt',
  'fjl',
  'bmw',
  'iva',
  'dx',
  'bps',
  'cyw',
  'pid',
  'bqf',
  'pr',
  'capxml',
  'qit',
  'xnt',
  'lok',
  'lpaq5',
];

export const getHumanizeDurationLanguage = () => {
  const appLanguage = store.getState().i18n.appLanguage;
  if (appLanguage.language.toLowerCase() === 'zh') {
    return `${appLanguage.language.toLowerCase()}_${appLanguage.country.toUpperCase()}`;
  }
  return appLanguage.language.toLowerCase();
};

export const printNewWindow = (newWindow: Window, file: Uint8Array, isMobile: boolean) => {
  const blob = new Blob([file], { type: 'application/pdf' });
  newWindow.location.replace(URL.createObjectURL(blob));
  !isMobile &&
    setTimeout(() => {
      newWindow.print();
    }, 200);
};

export const openDocumentUrl = (url: string, title: string, mimeType: string) =>
  fetch(url)
    .then(res => res.arrayBuffer())
    .then(result => {
      const newBlob = new Blob([result], { type: mimeType });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(newBlob);
      link.title = title;
      if (mimeType.toLowerCase().indexOf('pdf') > -1) {
        link.target = '_blank';
      } else {
        link.download = title;
      }
      link.click();
    });

export const generateRandomDigits = (): string => {
  const characters = '1234567890';
  return Array.from(Array(8)).reduce((acc: string) => acc + characters.charAt(Math.floor(Math.random() * 10)), '');
};

export const formatLocales = (availableLanguages: string[]): Locale[] =>
  availableLanguages
    .filter(availableLanguage => availableLanguage.split('-').length === 2)
    .map(availableLanguage => {
      const [language, country] = availableLanguage.split('-');
      return {
        language: language.toUpperCase(),
        country: country.toUpperCase(),
      };
    });

export const truncateId = (searchId: string) => searchId.substring(0, 16);

export const arrayUniqueByKey = (array: any[], key: string) => [...new Map(array.map(item => [item[key], item])).values()];

/**
 * Flips the keys and values of an object.
 *
 * @param data The object to flip.
 * @returns The flipped object.
 *
 * @example
 *
 * const data = { a: 1, b: 2, c: 3 };
 * const flippedData = flipObject(data);
 * // => { 1: 'a', 2: 'b', 3: 'c' }
 */
export function flipObject<T extends Record<string, any>>(data: T): ReverseMap<T> {
  return Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
}

/**
 * Removes duplicate values from an array.
 *
 * @param array  The array to deduplicate.
 *
 * @returns The deduplicated array.
 *
 * @example
 *
 * const array = [1, 2, 2, 3, 4, 4, 5];
 * const deduplicatedArray = deduplicate(array);
 * // => [1, 2, 3, 4, 5]
 */
export function deduplicate<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}
