import { createApi } from '@reduxjs/toolkit/query/react';

import { buildICareBackBaseQuery } from '../rtkQuery/baseQuery';
import { type InterventionInfos, type InterventionInfosRaw } from './interventions.types';
import { formatIntervention } from './intervention.helpers';

interface GetInterventionRequestParams {
  storeId: string;
  interventionId?: string;
  countryId?: number;
}

interface ErrorResponseApiRTK {
  status: number;
  data: {
    isOk: boolean;
    reason: string;
    code: string;
    id: string;
    status: number;
    error: Record<string, any>;
  };
}

export function isErrorResponse(error: unknown): error is ErrorResponseApiRTK {
  return typeof error === 'object' && error !== null && 'status' in error && 'data' in error;
}

export const interventionsApi = createApi({
  reducerPath: 'interventionsApi',
  baseQuery: buildICareBackBaseQuery('/workshop/interventions'),
  endpoints: builder => ({
    getInterventionDetails: builder.query<InterventionInfos, GetInterventionRequestParams>({
      query: ({ storeId, interventionId, countryId }) => ({ url: `/${storeId}/intervention/${interventionId}`, params: { countryId } }),
      transformResponse: (intervention: InterventionInfosRaw) => formatIntervention(intervention),
    }),
  }),
});
