import camelcaseKeys from 'camelcase-keys';
import cache from 'lscache';

import { buildHeaders, buildUrl, buildRequest, fetchRequest } from '../../utils/apis/RestApi';
import translate from '../../utils/i18n/SwappingIntlProvider';
import store from '../../store/store';
import {
  type MasterDataStore,
  type AvailableArticle,
  type Article,
  type MasterDataArticle,
  type MasterDataArticleRaw,
  type CatalogProduct,
  type MasterDataModelArticleRaw,
} from './masterdata.type';
import {
  articleBasedOnType,
  chunkArray,
  getHasSpareParts,
  getNaturesBySparePartAndServiceIds,
  getProductInformation,
  getProductsFromCatalog,
  iLinkRanges,
  NATURE_ID_WITHOUT_SPARE_PART,
} from '../ilink';
import { minutesToMidnight } from '../../utils/utils';
import { getModelsInformation, getPixlInfos } from '../spid';
import { getPrices } from '../prices';
import { getStockForArticles } from '../stockPicture';
import { getStoreId } from '../interventions/interventions';
import { handleError } from '../../business/helpers';
import { getArticleIdFromCode } from '../../business/referenceCodeHelper';
import { type InterventionNature, type Entity } from '../ilink.type';
import { type Brand } from '../dictionaries.type';
import { availableArticleLifeStages, type LifeStage } from '../../business/articleHelper';
import { cacheErrorLog } from '../logger';
import HttpError from '../../utils/apis/HttpError';

const masterdataStorageKey = 'masterdata';

export const getStoreById = (storeId: number): Promise<MasterDataStore> => {
  const key = `${masterdataStorageKey}-get-store-by-id-${storeId}`;
  const storedStore = cache.get(key);
  if (storedStore) {
    return Promise.resolve(storedStore);
  }
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `masterdata/v2/stores/${storeId}`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .then(data => camelcaseKeys<MasterDataStore>(data, { deep: true }))
    .then(MDStore => {
      try {
        cache.set(key, MDStore, minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return MDStore;
    })
    .catch(() => handleError('MasterData Request - GET store by id'));
};

export const checkAvailabilityForArticles = (articleIds: number[], lifestages: LifeStage[]): Promise<AvailableArticle[]> => {
  if (articleIds.some(articleId => isNaN(articleId))) {
    return Promise.reject(translate('error.message.invalid.searched.text'));
  }

  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl(
    'ICARE_BACK',
    `masterdata/check_availability_for_articles/${getStoreId()}/${articleIds}?lifeStages=${lifestages}&range=${iLinkRanges}`
  );
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .catch(() => handleError('MasterData Request - GET Check available article'));
};

export const getArticlesDetails = (articleIds: (number | string)[]) => {
  const url = buildUrl('ICARE_BACK', `masterdata/articles_information/${articleIds}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(data => camelcaseKeys<MasterDataArticleRaw[]>(data, { deep: true }))
    .catch(error => {
      if (error.response?.status === 404) {
        return [] as MasterDataArticleRaw[];
      }
      return handleError('MasterData Request - Articles details search');
    });
};

export const SERVICE_NATURE_ID = 10650;

export const isServiceNature = (modelCode: number): Promise<boolean> => {
  const url = buildUrl('ICARE_BACK', `masterdata/product_nature/${modelCode}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(result => result.natureId === SERVICE_NATURE_ID)
    .catch(() => false);
};

export const getSparePartFromCatalog = (code: string): Promise<InterventionNature> => {
  const interventionType = store.getState().intervention.interventionType;
  const key = `${masterdataStorageKey}-catalog-sp-${code}-${interventionType}`;
  const storedCatalogSP = cache.get(key);
  if (storedCatalogSP) {
    return Promise.resolve(storedCatalogSP);
  }

  return getArticleIdFromCode(code)
    .then(itemCode =>
      checkAvailabilityForArticles([itemCode], availableArticleLifeStages).then(([article]) => {
        if (article.available) {
          return itemCode;
        }
        throw new Error();
      })
    )
    .catch(() => {
      throw translate('error.message.unavailable.or.nonexistent.sparepart');
    })
    .then(itemCode => Promise.all([getArticlesDetails([itemCode]), getPrices([itemCode]), getStockForArticles([itemCode])]))
    .then(([[model], prices, stocks]) => {
      const modelCode = parseInt(model.modelId, 10);

      return Promise.all([isServiceNature(modelCode), getPixlInfos([modelCode]), getNaturesBySparePartAndServiceIds([modelCode], [])]).then(
        ([isService, pixlResult, naturesEntities]) => {
          if (isService) {
            throw translate('toaster.cart.warning.service.in.sparepart');
          }
          const price = prices.length ? prices[0] : { currency: '', value: 0 };
          const article: Article = {
            articleId: parseInt(model.itemId, 10),
            modelCode,
            labelGrid: model.valGridLabel,
            stock: stocks.length ? stocks[0].stock : 0,
            selected: true,
            value: price.value,
            available: true,
            unitQuantity: model.ueQuantity || 1,
            modelLib: model.modelLib,
            ...articleBasedOnType(),
          };

          const sparePart: Entity = {
            modelCode,
            name: model.modelLib,
            quantity: 1,
            articles: [article],
            unitQuantity: model.ueQuantity,
            imageUrl: pixlResult?.[0].imageUrl,
          };

          const result: InterventionNature = {
            natureId: naturesEntities.spareParts[0]?.natureIds[0] || NATURE_ID_WITHOUT_SPARE_PART,
            natureTitle: '',
            spareParts: [sparePart],
            services: [],
          };

          try {
            cache.set(key, result, minutesToMidnight());
          } catch (error) {
            cacheErrorLog(error);
          }
          return result;
        }
      );
    });
};

export const getArticleIdFromEAN = (eanCode: number): Promise<number> => {
  const key = `${masterdataStorageKey}-get-articleId-by-ean-${eanCode}`;
  const storedArticleId = cache.get(key);
  if (storedArticleId) {
    return Promise.resolve(storedArticleId);
  }
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `masterdata/v2/arbo/items/search?ean=${eanCode}&item_type=ARTICLE`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .then(data => {
      const result = parseInt(data.supplier_code, 10);
      try {
        cache.set(key, result, minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return result;
    })
    .catch(() => handleError('MasterData Request - GET ArticleId from EAN Code'));
};

export const getModelBrand = (modelCode: number): Promise<Brand> => {
  const key = `${masterdataStorageKey}-model-brand-${modelCode}`;
  const storedModelBrand = cache.get(key);

  if (storedModelBrand) {
    return Promise.resolve(JSON.parse(storedModelBrand));
  }

  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `masterdata/v2/brands/models/${modelCode}`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .then(result => {
      const brand: Brand = {
        id: result.id,
        name: result.brand_name,
      };
      try {
        cache.set(key, JSON.stringify(brand), minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return brand;
    })
    .catch(() => handleError('MasterData Request - GET Model Brand'));
};

export const getExistingOrUnknownIds = (ids: number[]) =>
  ids
    .map(id => cache.get(`${masterdataStorageKey}-${id}`) as MasterDataArticle[])
    .reduce(
      (acc: [MasterDataArticle[][], number[]], cachedModel, index) => {
        if (cachedModel) {
          acc[0].push(cachedModel);
        } else {
          acc[1].push(ids[index]);
        }
        return acc;
      },
      [[], []]
    );

const buildMasterDataArticle =
  (selected = false) =>
  (article: MasterDataArticleRaw): MasterDataArticle[] =>
    article.gridInfos.map(gridInfo => ({
      articleId: parseInt(article.itemId, 10),
      modelCode: parseInt(article.modelId, 10),
      gridId: gridInfo.numGrid,
      gridValueId: gridInfo.valGridId,
      labelGrid: gridInfo.valGridLabel,
      selected,
      eanNum: article.eanCode,
      numOrder: article.numOrder,
      modelLib: article.modelLib,
      unitQuantity: article.ueQuantity,
      nature: article.nature,
    }));

async function fetchArticlesFromModelCode(modelCode: number): Promise<MasterDataModelArticleRaw[]> {
  const url = buildUrl('ICARE_BACK', `masterdata/v2/arbo/models/${modelCode}/articles`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request).then(response => response.json());
}

export const getArticles = async (modelCodes: number[]): Promise<MasterDataArticle[]> => {
  try {
    const [existingModels, newModels] = getExistingOrUnknownIds(modelCodes);

    const articles: MasterDataModelArticleRaw[] = [];

    const chunkNewModels = chunkArray(newModels, 10);

    // eslint-disable-next-line no-restricted-syntax
    for (const chunkNewModel of chunkNewModels) {
      const chunkArticlesFromModelCode = await Promise.all(chunkNewModel.map(model => fetchArticlesFromModelCode(model)));

      const flattenArticles = chunkArticlesFromModelCode.flat();

      articles.push(...flattenArticles);
    }

    const chunkArticles = chunkArray(articles, 25);

    const articlesDetails = await Promise.all(
      chunkArticles.map(articleIds => getArticlesDetails(articleIds.map(article => article.article_id)))
    ).then(result => result.flat());

    const masterDataArticles = articlesDetails.map(buildMasterDataArticle(articles.length === 1)).flat();

    newModels.forEach(modelCode => {
      const key = `${masterdataStorageKey}-${modelCode}`;
      try {
        cache.set(
          key,
          masterDataArticles.filter(article => article.modelCode === modelCode),
          minutesToMidnight()
        );
      } catch (error) {
        cacheErrorLog(error);
      }
    });

    return existingModels.flat().concat(masterDataArticles);
  } catch (error) {
    if (error instanceof HttpError) {
      if (error.response?.status === 404) {
        return [];
      }
    }

    return handleError('MasterData Request - Articles search');
  }
};

export const getProductFromCatalog = (searchedCode: string): Promise<CatalogProduct | null> => {
  if (isNaN(Number(searchedCode))) {
    return Promise.resolve(null);
  }

  const key = `${masterdataStorageKey}-catalog-product-${searchedCode}`;
  const storedCatalogProduct = cache.get(key);
  if (storedCatalogProduct) {
    return Promise.resolve(storedCatalogProduct);
  }

  return getArticleIdFromCode(searchedCode).then(async code => {
    let articles = await getArticles([code]);
    if (articles.length === 0) {
      const articlesRaw = await getArticlesDetails([code]);
      articles = articlesRaw.map(buildMasterDataArticle(articlesRaw.length === 1)).flat();
    }
    const productCode = articles[0]?.modelCode;

    if (productCode) {
      const productInformation = await getProductInformation(productCode);
      const catalogProducts = !productInformation ? [] : await getProductsFromCatalog(productInformation.category.id);
      const isProductFromCatalog = catalogProducts.map(catalogModel => catalogModel.model_code).includes(productCode);
      const hasSparePartsPromise =
        isProductFromCatalog || !productInformation
          ? Promise.resolve(!!productInformation)
          : getHasSpareParts(undefined, productInformation.category.id);

      const [hasSpareParts, [modelInformation], brand] = await Promise.all([
        hasSparePartsPromise,
        getModelsInformation([productCode]),
        getModelBrand(productCode),
      ]);

      const result: CatalogProduct = {
        product: {
          id: modelInformation.modelCode,
          label: modelInformation.label,
          imageUrl: modelInformation.imageUrl,
          isCustom: !isProductFromCatalog,
          articles,
          brand,
        },
        productInformation,
        hasSpareParts,
      };

      try {
        cache.set(key, result, minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return result;
    }
    return null;
  });
};
