export const WARRANTY_POGO_ID = 2;
export const PAYABLE_POGO_ID = 3;
export const OFFERED_POGO_ID = 4;
export const OGEA_POGO_ID = 5;
export const INTERNAL_POGO_ID = 6;

export const RACQUET_SPORT_PROCESS = 1061;

export type POGO = 'GARANTI' | 'PAYANT' | 'OFFERT' | 'ASSURANCE OGEA';

export enum Pogos {
  GARANTI = 'GARANTI',
  PAYANT = 'PAYANT',
  OFFERT = 'OFFERT',
  'ASSURANCE OGEA' = 'ASSURANCE OGEA',
  INTERNAL = 'INTERNAL',
}

export const translatePogoToPogoId = (pogo: string | POGO) =>
  ({
    GARANTI: WARRANTY_POGO_ID,
    PAYANT: PAYABLE_POGO_ID,
    OFFERT: OFFERED_POGO_ID,
    'ASSURANCE OGEA': OGEA_POGO_ID,
    INTERNAL: INTERNAL_POGO_ID,
  }[pogo]);

export const translatePogoId = (pogoId: number) => {
  switch (pogoId) {
    case WARRANTY_POGO_ID:
      return Pogos.GARANTI;
    case OGEA_POGO_ID:
      return Pogos['ASSURANCE OGEA'];
    case OFFERED_POGO_ID:
      return Pogos.OFFERT;
    case INTERNAL_POGO_ID:
      return Pogos.INTERNAL;
    default:
      return Pogos.PAYANT;
  }
};

export const INVALID_INTERVENTION_STORE = 'INVALID_INTERVENTION_STORE';

export const INTERVENTION_ERRORS = [INVALID_INTERVENTION_STORE];

export const enum InterventionErrorCode {
  UNKNOWN_ERROR = '99',
}

export const CONTEXT_TYPE = {
  NEW_PRODUCT: 1,
  STANDARD: 2,
  SECOND_LIFE: 3,
  RENTAL: 5,
} as const;
