// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:900px){.qC7KymkZTQhQf8NdXz4nqQ\\=\\={position:relative}}", "",{"version":3,"sources":["webpack://./src/components/App.module.css"],"names":[],"mappings":"AAAA,yBACE,4BACE,iBACF,CACF","sourcesContent":["@media (max-width: 900px) {\n  .app {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "qC7KymkZTQhQf8NdXz4nqQ=="
};
export default ___CSS_LOADER_EXPORT___;
