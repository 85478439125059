import { type ILinkProductRaw, type ILinkProduct, type ProductInformation } from '../ilink.type';

export interface MasterDataStore {
  id: number;
  name?: string;
}

export interface MasterDataModel {
  article_id: string;
  label: string;
}

export interface AvailableArticle {
  articleId: number;
  available: boolean;
}

export interface MasterDataArticle {
  modelCode?: number;
  articleId: number;
  labelGrid?: string;
  selected?: boolean;
  gridId?: number;
  gridValueId?: string;
  eanNum?: string;
  free?: boolean;
  numOrder?: number;
  unitQuantity?: number;
  nature?: number;
  modelLib?: string;
}

export interface MasterDataArticleRaw {
  modelId: string;
  modelLib: string;
  itemId: string;
  numOrder: number;
  eanCode: string;
  valGridLabel?: string;
  nature: number;
  gridInfos: {
    numGrid: number;
    valGridId: string;
    valGridLabel: string;
  }[];
  ueQuantity: number;
}

export interface Article extends MasterDataArticle {
  value: number | null;
  priceType: string;
  stock?: number;
  estimatedTime?: number;
  available?: boolean;
  disabled?: boolean;
  stockMovementQuantity?: number;
  orderInProgress?: boolean;
}

export interface CatalogProduct {
  product: ILinkProduct;
  productInformation: ProductInformation | null;
  hasSpareParts: boolean;
}

export interface CatalogProductRaw {
  product: ILinkProductRaw;
  productInformation: ProductInformation | null;
  hasSpareParts: boolean;
}

export const enum NUM_GRID {
  GRID_THEORETICAL_TIME = 519,
}

export interface MasterDataModelArticleRaw {
  article_id: string;
  num_order: number;
  r3code: string;
}
