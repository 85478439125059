import { type MasterDataStore } from '../masterdata/masterdata.type';
import { type InterventionStatus } from '../../business/interventionButtonMenu';
import { type Filter } from '../../components/Filters/Filter';
import { type OrderRaw } from '../../pages/SparePartOrders/types';
import { type NullableDate } from '../../types/globals';

export interface InterventionTypeRaw {
  intervention_id: number;
  intervention_label?: string;
}

export enum InterventionType {
  standard = 1,
  /**
   * @deprecated Les pec de type new n'existent plus, et sont remplacées par les business
   */
  new = 2,
  homeService = 3,
  ogea = 4,
  express = 5,
  web = 6,
}

export interface FooterInfos {
  state: InterventionState;
  pledgeDate: Date;
  docNumber?: string;
  hasInvoice?: boolean;
  totalTheoreticalTime?: number;
}

export interface RealizationSite extends MasterDataStore {
  delay?: number;
}

export interface ServiceWorkshopRaw {
  cession_price: number;
  item_code: number;
  label?: string;
  model_code: number;
  pogo: string;
  pogo_id: number;
  quantity: number;
  theoretical_time: number;
  unit_price_without_taxes: number;
  theoretical_unit_price_without_taxes?: number;
  unit_price_with_taxes: number;
  theoretical_unit_price_with_taxes?: number;
}

export interface ServiceWorkshop {
  cessionPrice: number;
  itemCode: number;
  label: string;
  modelCode: number;
  pogo: string;
  pogoId: number;
  quantity: number;
  theoreticalTime: number;
  unitPriceWithoutTaxes: number;
  theoreticalUnitPriceWithoutTaxes?: number;
  unitPriceWithTaxes: number;
  theoreticalUnitPriceWithTaxes?: number;
}

export interface InterventionPogoItem {
  modelCode: number;
  pogoId: number;
}

export interface OrderWorkshopRaw {
  order_number: number;
  status: string;
  warehouse?: number;
  delivery_date?: string;
  received_date?: string;
}

export interface SparePartWorkshopRaw {
  item_code: number;
  order_in_progress: boolean;
  pogo: string;
  pogo_id: number;
  quantity: number;
  unit_price_with_taxes: number;
  theoretical_unit_price_with_taxes?: number;
  unit_price_without_taxes: number;
  theoretical_unit_price_without_taxes?: number;
  wacp_price: number;
  orders: OrderWorkshopRaw[];
  stock_movement_quantity: number;
  order_numbers?: number[];
}

export interface SparePartWorkshop {
  modelCode: number;
  itemCode: number;
  orderInProgress: boolean;
  pogo: string;
  pogoId: number;
  quantity: number;
  unitPriceWithTaxes: number;
  theoreticalUnitPriceWithTaxes?: number;
  unitPriceWithoutTaxes: number;
  theoreticalUnitPriceWithoutTaxes?: number;
  wacpPrice: number;
  orders: OrderRaw[];
  stockMovementQuantity: number;
  orderNumbers: number[];
  label?: string;
}

export interface InterventionEventFromSearch {
  date: string;
  eventId: EventStage;
}

export interface WorkshopIntervention {
  stateId: number;
  stateLabel?: string;
  documentNumber: string;
  invoiceNumber?: string;
  isLocal: boolean;
  isWarranty?: boolean;
  creationSite: number;
  realisationSite: number;
  creationDate: Date;
  interventionType: InterventionType;
  customerLanguage: string;
  depositedProduct: DepositedProduct;
  totalTheoreticalTime: number;
  currencyCode: string;
  familyLabel?: string;
  familyId: number;
  services?: ServiceWorkshop[];
  spareParts?: SparePartWorkshop[];
  customerAlertType: string;
  availabilityDate?: NullableDate;
  contextId?: number;
  memberId?: string;
  displayName?: string;
  storeComment?: string;
  workshopComment?: string;
  traceabilityNumber?: string;
  stringTension?: string;
  externalReferenceNumber?: string;
  maxOgeaAmount?: number;
  validateTotalPrice?: number;
  totalPriceWithTaxes?: number;
  events?: InterventionEventFromSearch[];
}

export interface WorkshopInterventionRaw {
  services: ServiceWorkshopRaw[];
  document_number: string;
  is_local: boolean;
  creation_site: number;
  realisation_site: number;
  creation_date: string;
  intervention_type: number;
  context_id: number;
  customer_language: string;
  deposited_product?: DepositedProductRaw;
  total_theoretical_time: number;
  total_price_with_taxes: number;
  currency_code: string;
  family_id: number;
  spare_parts: SparePartWorkshopRaw[];
  max_ogea_amount: number;
  state_label: string;
  state_id: number;
  member_id: string;
  customer_alert_type: 'BOTH' | 'EMAIL' | 'PHONE_NUMBER' | 'NONE';
  availability_date: string;
}

export interface InterventionDates {
  event_id: number;
  event_date: string;
  label: string;
}

export interface InterventionStateRaw {
  state_id: number;
  state_label?: string;
}

export interface InterventionDocument {
  fileContent: string;
  fileName: string;
}

export interface InterventionState {
  stateId: InterventionStatus;
  stateLabel?: string;
  document?: InterventionDocument;
}

export interface GetInterventionsIdsParams {
  toDoEvents?: ToDoEvent[];
  processes?: number[];
  fromDate?: Date;
  toDate?: Date;
  toSelf?: boolean;
  nextParams?: string;
}

interface DepositedProductRaw {
  item_code?: number;
  category_id?: number;
  purchase_date?: string;
}

export interface DepositedProduct {
  itemCode?: number;
  modelCode?: number;
  label?: string;
  purchaseDate?: Date;
  location?: string | null;
  categoryId?: number;
}

export interface InterventionProduct extends Omit<DepositedProduct, 'purchaseDate'> {
  purchaseDate?: string;
}

export type CommentType = 'LOCAL' | 'REGIONAL';

export interface PogoDetail {
  pogoId: number;
  linkedPogoId?: number;
}

export interface Context {
  id: number;
  internal: boolean;
  pogoList: PogoDetail[];
}

export interface StoreRaw {
  site_number: number;
  site_type: string;
  site_name: string;
  regional_workshops?: StoreRaw[];
  max_express_amount?: number;
  allow_sad: boolean;
  display_price_without_taxes?: boolean;
  contexts?: Context[];
}

export interface StoreInformation {
  id: number;
  isRegionalWorkshop: boolean;
  label: string;
  maxExpressAmount: number;
  allowSad: boolean;
  displayPriceWithoutTaxes: boolean;
  contexts: Context[];
}

export interface CreationResponseRaw extends ServicesResponse {
  document_number: string;
  state: InterventionStateRaw;
}

export interface ServicesResponse {
  services?: {
    services: ServiceWorkshop[];
  };
}

export interface CreationResponse {
  docNumber: string;
  state: InterventionState;
  reload?: boolean;
  errorMessages?: string[];
}

export interface RawShippingReturn {
  interventions: [
    {
      document_number: string;
      state: {
        state_id: number;
        state_label: string;
      };
    }
  ];
  parcel_number: string;
}

export interface ShippingResponse extends CreationResponse {
  parcelNumber: string;
}

export enum PDFType {
  invoice = 'invoice',
  order = 'order',
  red = 'red',
  quote = 'quote',
}

export interface DelayRaw {
  family_id: number;
  delay: number;
}

export interface Delay {
  familyId: number;
  delay: number;
}

export type StateParam = '/refuse' | '/delete' | '';

/**
 * @deprecated switch to `InterventionsEventsType`
 */
export const enum EventStage {
  creation = 1,
  purchaseDate = 2,
  pledgeDate = 3,
  validation = 4,
  sentToRW = 5,
  sentToStore = 6,
  billing = 7,
  closing = 8,
  treatedIntervention = 9,
  receivedInRW = 10,
  treatedAndClosed = 11,
  removed = 12,
  storeStockShortage = 13,
  stockRWShortage = 14,
  refused = 15,
}

export interface InterventionTimeStamp {
  eventDate: string;
  eventId: EventStage;
  label: string;
  technician: string;
}

export const enum InterventionsEventsType {
  ITV_CREATION_AMOUNT_DATE = 1,
  ITV_PURCHASE_DATE = 2,
  ITV_END_DATE = 3,
  ITV_VALIDATION_DATE = 4,
  ITV_SENT_TO_AR_DATE = 5,
  ITV_SENT_TO_STORE_DATE = 6,
  ITV_INVOICE_CREATION_DATE = 7,
  ITV_CLOTURE_DATE = 8,
  ITV_AVAILABLE_DATE = 9,
  ITV_RECEPTION_AR_DATE = 10,
  ITV_CHECK_OUT_DATE = 11,
  ITV_DELETE_DATE = 12,
  STORE_STOCK_SHORTAGE = 13,
  RW_STOCK_SHORTAGE = 14,
  ITV_REFUSAL_CUSTOMER_DATE = 15,
  ITV_ASSIGN_USER = 1000,
  ITV_UNASSIGNED_USER = 1001,
}

export interface DelayDetail {
  familyId: number;
  delay: number;
}

export interface RegionalWorkshopDelay {
  localWorkshop: number;
  delayDetails: DelayDetail[];
}

export interface ToDoEvent {
  date: Date;
  interventionIds: string[];
}

export interface EntityQuantityUpdate {
  categoryId: number;
  modelCode: number;
  quantity: number;
  natureId?: number;
  contextType: WorkshopContextType | null;
}

export interface WorkshopContextType {
  id: number;
  internal: boolean;
  authorizedPogos: PogoDetail[];
}

export interface InterventionUpdateType {
  interventionType: InterventionType;
  workshopInterventionContextTypes: WorkshopContextType[];
  hasCustomer?: boolean;
}

export const STANDARD_INTERNAL_BUSINESS_ID = 1;
export const STANDARD_EXTERNAL_BUSINESS_ID = 2;
export const SECOND_LIFE_INTERNAL_BUSINESS_ID = 3;
export const RENTAL_INTERNAL_BUSINESS_ID = 5;

export enum NotificationStage {
  emailConfirmation = 211,
  emailProductAvailable = 213,
  smsProductAvailable = 214,
}

export enum HistoryStage {
  addService = 111,
  removeService = 112,
  addSparePart = 113,
  removeSparePart = 114,
  quantityChangeService = 115,
  quantityChangeSparePart = 116,
  pogoChangeService = 118,
  pogoChangeSparePart = 119,
  pledgeDateChange = 120,
}

export interface InterventionEvent {
  timestampDate: string;
  eventId: InterventionsEventsType;
  technician: string;
}

export interface InterventionNotification {
  timestampDate: string;
  notificationId: NotificationStage;
}

export interface InterventionHistory {
  timestampDate: string;
  historyId: HistoryStage;
  technician: string;
  articleCode: number;
  modelCode: number;
  oldValue: string | null;
  newValue: string | null;
}

export interface InterventionTimestampResponse {
  events: InterventionEvent[];
  notifications: InterventionNotification[];
  history: InterventionHistory[];
}

export enum TimeStampType {
  EVENT = 'EVENT',
  NOTIFICATION = 'NOTIFICATION',
  HISTORY = 'HISTORY',
}

export interface InterventionTimestamp {
  timestampId: FullEvent;
  timestampDate: string;
  technician: string;
  type: TimeStampType;
}

export type FullEvent = InterventionsEventsType | NotificationStage | HistoryStage;

export const enum CustomerAlertType {
  NONE = 'NONE',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  BOTH = 'BOTH',
}

interface EntitiesPogoBody {
  pogoId: number;
  quantity: number;
}

export interface ServicesPogoBody extends EntitiesPogoBody {
  serviceModelCode: number;
}
export interface SparePartsPogoBody extends EntitiesPogoBody {
  sparePartCode: number;
  orderNumbers?: number[];
}
export enum InterventionContextId {
  newInternal = 1,
  newExternal = 2,
  secondLifeInternal = 3,
  secondLifeExternal = 4,
  locationInternal = 5,
  locationExternal = 6,
}

export interface PurchaseIntervention {
  documentNumber: string;
  services: {
    itemCode: number;
    label: string;
  }[];
  realisationSite: string;
  type: number;
  creationDate: string;
  totalPrice: number;
  currency: string;
}

export interface FillInterventionsInformationParams {
  interventions: WorkshopIntervention[];
  withoutIdentity?: boolean;
  processes?: Filter[];
}

export interface UrlForGlobalSearchParams {
  stateIds: InterventionStatus[];
  nextParams: string;
  isRealizationSite: boolean;
  storeId: number;
  isRegionalWorkshop?: boolean;
  fromDate?: Date;
  toDate?: Date;
  processes?: Filter[];
  interventionTypes?: Filter[];
  filteredInternal?: boolean;
  childStoreId?: number;
}

export interface WboInterventionSearchRaw {
  created_date: string;
  creation_site: string;
  currency_code: string;
  customer: {
    member_id: string | null;
    cell_phone_alert: boolean | null;
    email_alert: boolean | null;
  };
  document_number: string;
  family_id: number;
  is_local: boolean;
  language: string | null;
  model: {
    item_code: number;
    category_id: number;
    product_label: string | null;
    location: string | null;
    id: number;
    created_date: string;
    purchase_date: string | null;
  };
  pledge_date: string | null;
  realisation_site: string;
  ref_context: {
    name: string;
    id: number;
  };
  states: {
    state: { id: number };
  };
  store_comment: string | null;
  total: {
    price_with_taxes: number;
    theoretical_time: number;
  };
  type: { id: number };
  events: {
    date: string;
    event_id: number;
  }[];
  validate_total_price: number;
}

export interface WboGlobalSearchRaw {
  content: WboInterventionSearchRaw[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  number_of_elements: number;
  pageable: {
    page_number: number;
    page_size: number;
    size: number;
  };
  total_elements: number;
  total_pages: number;
}

export interface ConvertCustomerAlertType {
  cell_phone_alert: boolean | null;
  email_alert: boolean | null;
}

export interface InterventionError {
  error_code: string;
  error_id: string;
  error_description: string;
  link: string;
  timestamp: string;
  message: string;
  details: string;
  error?: Record<string, unknown>;
}
