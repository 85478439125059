import { type SparePartOrderStatus } from './types';

export const DEFAULT_ACTIVE_TAB = 'received';

export const RECEIVED = 'RECEIVED';
export const WAITING_VALIDATION = 'WAITING_VALIDATION';
export const VALIDATED_WAITING_TO_BE_SENT = 'VALIDATED_WAITING_TO_BE_SENT';
export const CONFIRMED = 'CONFIRMED';
export const IN_TRUCK = 'IN_TRUCK';
export const PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED';
export const PRECONFIRMED = 'PRECONFIRMED';
export const SENT_TO_SUPPLIER = 'SENT_TO_SUPPLIER';
export const STOCK_OUT = 'STOCK_OUT';
export const CANCELED = 'CANCELED';
export const ORDER_DATE_OVERDUE = 'ORDER_DATE_OVERDUE';
export const ORDER_DELAY = 'ORDER_DELAY';
export const ORDER_CANCELLED = 'ORDER_CANCELLED';

export const RECEIVED_ORDER_STATUS: SparePartOrderStatus[] = [RECEIVED];
export const DELIVERY_ORDER_STATUS: SparePartOrderStatus[] = [
  WAITING_VALIDATION,
  VALIDATED_WAITING_TO_BE_SENT,
  CONFIRMED,
  IN_TRUCK,
  PARTIALLY_RECEIVED,
  PRECONFIRMED,
  SENT_TO_SUPPLIER,
];
export const ALERT_ORDER_STATUS: SparePartOrderStatus[] = [STOCK_OUT, CANCELED, ORDER_DATE_OVERDUE, ORDER_DELAY, ORDER_CANCELLED];

export const ALL_ORDER_STATUSES = [RECEIVED_ORDER_STATUS, DELIVERY_ORDER_STATUS, ALERT_ORDER_STATUS].join(',');

export const SKELETON_LABEL = 'skeleton-label';
export const SKELETON_ITEM_CODE = 'skeleton-item-code';
export const SKELETON_IMAGE = 'skeleton-image';
export const SKELETON_QUANTITY = 'skeleton-quantity';
export const SKELETON_INTERVENTION = 'skeleton-intervention-id';
export const SKELETON_PRODUCT_LABEL = 'skeleton-product-label';
export const SKELETON_RECEIVED_DATE = 'skeleton-received-date';
export const SKELETON_DELIVERY_DATE = 'skeleton-delivery-date';
export const SKELETON_ROW = 'skeleton-row';

export const SKELETON_IDS = [
  SKELETON_LABEL,
  SKELETON_IMAGE,
  SKELETON_QUANTITY,
  SKELETON_INTERVENTION,
  SKELETON_PRODUCT_LABEL,
  SKELETON_RECEIVED_DATE,
  SKELETON_DELIVERY_DATE,
];

export const ORDER_STATUS_COLORS = {
  WAITING_VALIDATION: 'yellow',
  VALIDATED_WAITING_TO_BE_SENT: 'yellow',
  SENT_TO_SUPPLIER: 'yellow',
  PRECONFIRMED: 'yellow',
  CONFIRMED: 'yellow',
  STOCK_OUT: 'red',
  CANCELED: 'red',
  IN_TRUCK: 'yellow',
  PARTIALLY_RECEIVED: 'yellow',
  RECEIVED: 'green',
  ORDER_DATE_OVERDUE: 'red',
  ORDER_DELAY: 'red',
  ORDER_CANCELLED: 'red',
};
