import React, { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { VtmnBreadcrumb, VtmnBreadcrumbItem } from '@vtmn/react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import translate from '../../utils/i18n/SwappingIntlProvider';
import { useFetchIlinkProcesses } from '../../apis/useFetch/ilink-process';
import { interventionNotStarted } from '../../store/selectors/intervention';
import { handleEnterOrSpace } from '../../business/eventHelper';
import { useCatalogStore } from '../../store/hooks';
import { type BreadcrumbLevel, type CatalogSearch } from '../../store/catalogSlice';
import { type ILinkProduct, LevelType } from '../../apis/ilink.type';
import { usePrompt } from './TempBlocker';
import { Action, type Location } from './History';

import './breadcrumb.scss';

export const initialBreadcrumb = () => ({
  level: LevelType.processes,
  label: translate('catalog.breadcrumb.search.root'),
});

export interface BreadcrumbProps {
  setUserInputSearch?: (value: string) => void;
  backToCatalog?: boolean;
}

const getBreadcrumbLabel = (breadcrumbLevel: BreadcrumbLevel, iLinkResult: string | undefined) =>
  breadcrumbLevel.label ?? iLinkResult ?? breadcrumbLevel.id?.toString() ?? breadcrumbLevel.level;

const isBreadcrumbSelectable = (index: number, breadcrumbLength: number) => index !== breadcrumbLength - 1;

const BreadCrumbItem = ({
  breadcrumbLevel,
  catalogSearch,
  breadcrumbAction,
  product,
  index,
}: {
  breadcrumbLevel: BreadcrumbLevel;
  catalogSearch: CatalogSearch;
  product: ILinkProduct;
  breadcrumbAction: (breadcrumb: BreadcrumbLevel, preventNavigation?: boolean) => void;
  index: number;
}) => {
  const ilinkProcessesRequest = useFetchIlinkProcesses(breadcrumbLevel.id?.toString() ?? breadcrumbLevel.label ?? breadcrumbLevel.level);
  useEffect(() => {
    !ilinkProcessesRequest.result && breadcrumbLevel.id && breadcrumbLevel.label === undefined && ilinkProcessesRequest.fetchResult();
  }, [breadcrumbLevel.id]);

  return (
    <VtmnBreadcrumbItem key={breadcrumbLevel.level}>
      <span
        role="button"
        className={clsx('breadcrumb-name', { blocked: product.isFromPurchase && breadcrumbLevel.level === LevelType.products })}
        tabIndex={isBreadcrumbSelectable(index, catalogSearch.breadcrumb.length) ? 0 : -1}
        onKeyDown={event =>
          isBreadcrumbSelectable(index, catalogSearch.breadcrumb.length) && handleEnterOrSpace(() => breadcrumbAction(breadcrumbLevel))(event)
        }
        onClick={() => breadcrumbAction(breadcrumbLevel)}
      >
        {getBreadcrumbLabel(breadcrumbLevel, ilinkProcessesRequest.result?.label)}
      </span>
    </VtmnBreadcrumbItem>
  );
};

export const Breadcrumb = ({ setUserInputSearch, backToCatalog }: BreadcrumbProps) => {
  const { catalogSearch, updateCatalogSearch, product } = useCatalogStore();
  const isInterventionNotStarted = useSelector(interventionNotStarted);
  const navigate = useNavigate();
  const location = useLocation();
  const breadcrumbSearch = catalogSearch.breadcrumb;

  const breadcrumbAction = (breadcrumb: BreadcrumbLevel, preventNavigation = false) => {
    if (catalogSearch.breadcrumb.indexOf(breadcrumb) !== catalogSearch.breadcrumb.length - 1) {
      setUserInputSearch?.('');
      const updatedBreadcrumbSearch = [...breadcrumbSearch].splice(0, breadcrumbSearch.indexOf(breadcrumb) + 1);
      updateCatalogSearch({ ...catalogSearch, breadcrumb: updatedBreadcrumbSearch, fetchNeeded: true });
      backToCatalog && !preventNavigation && navigate(`/catalog/${!isInterventionNotStarted ? 'intervention' : 'product'}`);
    }
  };

  const displayBreadcrumb = useCallback(
    (breadcrumbLevel: BreadcrumbLevel) =>
      !breadcrumbLevel.hide &&
      (breadcrumbLevel.level !== LevelType.processes || (breadcrumbLevel.level === LevelType.processes && isInterventionNotStarted)),
    []
  );

  usePrompt((nextLocation: Location, action: Action) => {
    if (
      (nextLocation.pathname === '/product' || nextLocation.pathname === '/intervention' || nextLocation.pathname === '/product/intervention') &&
      action !== Action.Pop
    ) {
      return true;
    }

    if (location.pathname === '/product' && nextLocation.pathname.startsWith('/catalog')) {
      catalogSearch.breadcrumb[catalogSearch.breadcrumb.length - 1].level === LevelType.product &&
        action === Action.Pop &&
        breadcrumbAction(catalogSearch.breadcrumb[catalogSearch.breadcrumb.length - 2], true);
      return true;
    }

    if (
      location.pathname.startsWith('/catalog') &&
      catalogSearch.breadcrumb.length > 1 &&
      action === Action.Pop &&
      displayBreadcrumb(catalogSearch.breadcrumb[catalogSearch.breadcrumb.length - 2])
    ) {
      breadcrumbAction(catalogSearch.breadcrumb[catalogSearch.breadcrumb.length - 2]);
      return false;
    }

    return true;
  });

  return (
    <VtmnBreadcrumb className="breadcrumb">
      {!!catalogSearch.breadcrumb?.length &&
        catalogSearch.breadcrumb.map(
          (breadcrumbLevel, index) =>
            displayBreadcrumb(breadcrumbLevel) && (
              <BreadCrumbItem
                breadcrumbAction={breadcrumbAction}
                breadcrumbLevel={breadcrumbLevel}
                catalogSearch={catalogSearch}
                index={index}
                product={product}
                key={breadcrumbLevel.level}
              />
            )
        )}
    </VtmnBreadcrumb>
  );
};
