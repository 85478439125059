import { type InterventionError, type InterventionInfosRaw, type InterventionInfos } from './interventions.types';

export function formatIntervention(intervention: InterventionInfosRaw): InterventionInfos {
  const creationDate = new Date(intervention.creationDate);
  const availabilityDate = intervention.availabilityDate ? new Date(intervention.availabilityDate) : null;
  const purchaseDate = intervention.depositedProduct?.purchaseDate ? new Date(intervention.depositedProduct.purchaseDate) : undefined;

  return {
    ...intervention,
    creationDate,
    availabilityDate,
    depositedProduct: {
      ...intervention.depositedProduct,
      purchaseDate,
    },
  };
}
/* eslint-disable react/destructuring-assignment */

export const IsInterventionError = (error: InterventionError) => {
  if (error?.details && error.error_code && error.error_id && error.message) {
    return error;
  }
  return null;
};
