import React, { createContext, useState, useMemo, useEffect, useContext, type ReactNode } from 'react';
import cache from 'lscache';
import { RequestMethod } from '@chipp972/redux-ajax';
import { useSelector } from 'react-redux';

import { getInterventionTypesFilter } from '../../business/interventionHelper';
import { buildUrl, useBuildHeader } from '../../utils/apis/RestApi';
import { selectCountryId } from '../../store/selectors/userInfo';
import { useProcessesRequest } from './planning.helper';
import { usePlanningStateFilter } from '../../utils/hooks/usePlanningStateFilter';
import { type Intervention } from '../Interventions/Interventions';
import type { Filter } from '../Filters/Filter';
import { getCurrentDate } from '../../utils/date/date-utils';

export interface InterventionsProviderProps {
  processesReady: boolean;
  processes: Filter[];
  interventionTypesFilter: Filter[];
  interventionStatesFilter: Filter[];
  beginDate: Date;
  endDate?: Date;
  interventionIds: string[];
  interventions: Intervention[];
  customerFullName: string;
  setProcesses: (processes: Filter[]) => void;
  setInterventionTypesFilter: (processes: Filter[]) => void;
  setInterventionStatesFilter: (processes: Filter[]) => void;
  setBeginDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setInterventionIds: (interventionIds: string[]) => void;
  setInterventions: (interventions: Intervention[]) => void;
  setCustomerFullName: (customerFullName: string) => void;
}

export const InterventionContext = createContext<InterventionsProviderProps>({} as any);

interface InterventionsProviderComponentProps {
  children: ReactNode;
}

export const KEY_CACHE_PROCESSES = 'processes';
const keyCacheInterventionType = 'saved-intervention-types';

export const InterventionsProvider = ({ children }: InterventionsProviderComponentProps) => {
  const defaultInterventionTypes: Filter[] = cache.get(keyCacheInterventionType);
  const [processes, setProcessesLocal] = useState<Filter[]>(JSON.parse(localStorage.getItem(KEY_CACHE_PROCESSES) ?? '[]'));
  const [interventionTypesFilter, setInterventionTypesFilterLocal] = useState<Filter[]>(defaultInterventionTypes || getInterventionTypesFilter());
  const stateFilters = usePlanningStateFilter();
  const [interventionStatesFilter, setInterventionStatesFilter] = useState<Filter[]>(stateFilters);
  const [beginDate, setBeginDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState<undefined | Date>(getCurrentDate());
  const [interventionIds, setInterventionIds] = useState<string[]>([]);
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [customerFullName, setCustomerFullName] = useState<string>('');
  const countryId = useSelector(selectCountryId);
  const headers = useBuildHeader({ serviceName: 'WORKSHOP_V3', tokenType: 'Bearer', fullLocale: true });

  const setProcesses = (processesToUpdate: Filter[]) => {
    setProcessesLocal(processesToUpdate);
    localStorage.setItem(KEY_CACHE_PROCESSES, JSON.stringify(processesToUpdate));
  };
  const processesRequest = useProcessesRequest();
  const processesReady = processes.length > 0;

  useEffect(() => {
    const url = buildUrl('ICARE_BACK', `ilink/processes/${countryId}`);
    !processesReady &&
      processesRequest.submitRequest({
        requestContent: { method: RequestMethod.get, url, headers },
      });
  }, []);

  useEffect(() => {
    if (processesRequest.hasResponse) {
      const processesFromRequest = processesRequest.response.map(process => ({ id: process.id, label: process.label, selected: true }));
      setProcesses(processesFromRequest);
    }
  }, [processesRequest.response]);

  const setInterventionTypesFilter = (interventionTypesToUpdate: Filter[]) => {
    setInterventionTypesFilterLocal(interventionTypesToUpdate);
    cache.set(keyCacheInterventionType, interventionTypesToUpdate);
  };

  const value: InterventionsProviderProps = useMemo(
    () => ({
      processesReady,
      processes,
      interventionStatesFilter,
      interventionTypesFilter,
      setProcesses,
      beginDate,
      setBeginDate,
      endDate,
      setEndDate,
      interventionIds,
      setInterventionIds,
      interventions,
      setInterventions,
      customerFullName,
      setCustomerFullName,
      setInterventionStatesFilter,
      setInterventionTypesFilter,
    }),
    [processes, interventionIds, interventionStatesFilter, interventionTypesFilter, interventions, endDate, beginDate, customerFullName]
  );

  return <InterventionContext.Provider value={value}>{children}</InterventionContext.Provider>;
};

export const useInterventionContext = () => {
  const interventionContext = useContext(InterventionContext);

  if (!interventionContext) {
    throw new Error('useInterventionContext must be used inside the InterventionProvider');
  }

  return interventionContext;
};
