// @ts-strict-ignore
import { type DependencyList, type MutableRefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';

import store from '../../store/store';
import { selectIsMobile } from '../../store/selectors/app';
import { articleBasedOnType, chunkArray } from '../../apis/ilink';
import { checkAvailabilityForArticles, getArticles } from '../../apis/masterdata/masterdata';
import { getStockForArticles } from '../../apis/stockPicture';
import { type StockPictureResponse } from '../../apis/stockPicture.type';
import { type Article, type AvailableArticle, type MasterDataArticle } from '../../apis/masterdata/masterdata.type';
import { type InterventionType } from '../../apis/interventions/intervention.type';
import { availableArticleLifeStages, emptyArticle, findArticleId, LifeStage } from '../../business/articleHelper';
import { getPrices } from '../../apis/prices';
import { type Price } from '../../apis/prices.type';
import { buildServiceArticles, buildSparePartArticles } from '../../business/entityHelper';

export const displayAssociatedEntities = (associatedEntities: HTMLDivElement, arrow: HTMLDivElement) => {
  const { top: parentTop, bottom: parentBottom } = associatedEntities.parentElement.getBoundingClientRect();
  associatedEntities.style.left = '';
  associatedEntities.style.top = '';
  associatedEntities.style.width = `${window.innerWidth - 260}px`;
  const isTop = window.innerHeight - (parentTop + (parentBottom - parentTop)) - 85 < associatedEntities.clientHeight + 20;
  arrow.classList.toggle('top', isTop);
  const translateX = associatedEntities.getBoundingClientRect().left - 160;
  const translateY = isTop ? associatedEntities.clientHeight + 20 : associatedEntities.parentElement.clientHeight + 20;
  associatedEntities.style.left = `-${translateX}px`;
  associatedEntities.style.top = `${isTop ? '-' : ''}${translateY}px`;
  associatedEntities.style.visibility = 'visible';
  arrow.style.visibility = 'visible';
};

type DisplayAssociatedServicesProps = {
  overlayElement: MutableRefObject<HTMLDivElement>;
  arrowElement: MutableRefObject<HTMLDivElement>;
  dependencies: DependencyList;
};

export const useDisplayAssociatedServices = ({ overlayElement, arrowElement, dependencies }: DisplayAssociatedServicesProps) => {
  const isMobile = useSelector(selectIsMobile);

  useEffect(() => {
    if (!isMobile && overlayElement.current) {
      displayAssociatedEntities(overlayElement.current, arrowElement.current);
    }
  }, dependencies);
};

type ArticlesInformation = [number[], Price[], AvailableArticle[], StockPictureResponse[] | null];
type ArticlesCallBack = (articles: Article[], masterdataArticles: MasterDataArticle[]) => Article[];

const getArticlesInformation = (modelCodes: number[], lifestages: LifeStage[], callback: ArticlesCallBack, getStock: boolean): Promise<Article[]> =>
  getArticles(modelCodes).then(masterdataArticles => {
    const chunkArticles = chunkArray([...new Set(masterdataArticles.map(article => article.articleId))], 20);
    return Promise.all(
      chunkArticles.map(articleIds =>
        Promise.all([
          Promise.resolve(articleIds),
          getPrices(articleIds).catch(() => null),
          checkAvailabilityForArticles(articleIds, lifestages),
          getStock ? getStockForArticles(articleIds) : Promise.resolve(null),
        ])
      )
    )
      .then(articleInformations =>
        articleInformations.flatMap(([articleIds, prices, availabilities, stocks]: ArticlesInformation) =>
          articleIds.map(articleId => {
            const price = prices === null ? ({ value: null } as Price) : prices.find(findArticleId(articleId));
            const articleStock = stocks?.find(stock => stock.item === articleId);
            const availability = availabilities.find(findArticleId(articleId));
            return { ...price, stock: articleStock?.stock ?? 0, ...availability };
          })
        )
      )
      .then(articles => callback(articles, masterdataArticles));
  });

export const fillSparePartsArticles = (modelCodes: number[]): Promise<Article[]> =>
  getArticlesInformation(
    modelCodes,
    availableArticleLifeStages,
    (articles: Article[], masterdataArticles: MasterDataArticle[]) =>
      modelCodes
        .map(modelCode =>
          buildSparePartArticles(
            masterdataArticles
              .filter(article => article.modelCode === modelCode)
              .map(article => ({
                ...emptyArticle(),
                ...article,
                ...articles.find(findArticleId(article.articleId)),
                ...articleBasedOnType(),
              }))
          )
        )
        .flat(),
    true
  ).then(articles => [...new Map(articles.map(article => [article.articleId, article])).values()]);

export const getServicesArticles = (modelCodes: number[], interventionType?: InterventionType): Promise<Article[]> =>
  getArticlesInformation(
    modelCodes,
    [LifeStage.active],
    (articles: Article[], masterdataArticles: MasterDataArticle[]) =>
      modelCodes
        .map(modelCode =>
          buildServiceArticles(
            masterdataArticles
              .filter(
                article => article.modelCode === modelCode && articles.some(articleInformation => articleInformation.articleId === article.articleId)
              )
              .map(article => ({
                ...emptyArticle(),
                ...article,
                ...articles.find(findArticleId(article.articleId)),
              })),
            interventionType ?? store.getState().intervention.interventionType
          )
        )
        .flat(),
    false
  );
