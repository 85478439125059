import { createApi } from '@reduxjs/toolkit/query/react';

import { buildICareBackBaseQuery } from '../rtkQuery/baseQuery';
import { type GetBookingsRequestParams, type Booking } from './bookings.types';

export const bookingsApi = createApi({
  reducerPath: 'bookingsApi',
  baseQuery: buildICareBackBaseQuery('/bookings'),
  tagTypes: ['Booking'],
  endpoints: builder => ({
    getBookings: builder.query<Booking[], GetBookingsRequestParams>({
      query: params => ({
        url: '/',
        params,
      }),
      providesTags: result => result?.map(({ id }) => ({ type: 'Booking', id })) || [],
    }),
    signalInterventionCreation: builder.mutation<unknown, string>({
      query: bookingId => ({
        url: `/${bookingId}/signal_intervention_creation`,
        method: 'PATCH',
        responseHandler: 'content-type',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Booking', id }],
    }),
    signalNoShow: builder.mutation<unknown, string>({
      query: bookingId => ({
        url: `/${bookingId}/signal_no_show`,
        method: 'PATCH',
        responseHandler: 'content-type',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Booking', id }],
    }),
  }),
});
