// @ts-strict-ignore
import React, { type ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

export const colors = {
  green: '#02BE8A',
  orange: '#FE5800',
  red: '#ff0000',
  white: '#FFF',
  grey: '#4E5D6B',
  blueDKT: '#0082C3',
  lightBlueDKT: 'rgba(0, 130, 195, 0.9)',
  redDKT: '#C9110D',
};

export interface ToastProps {
  id: string;
  color?: string;
  content: string | Error;
  duration: number;
  reload?: boolean;
}

interface ToastProviderContextProps {
  addToast: (content: string, color?: string, duration?: number, reload?: boolean) => void;
  /**
   * @deprecated Use addToast, will be remove shortly
   */
  toaster: (content: string, color?: string, duration?: number, reload?: boolean) => void;
  toasts: ToastProps[];
  removeToast: (toastId: string) => void;
}

const initialState: ToastProps[] = [];

const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    addToastAction: (state, action: PayloadAction<ToastProps>) => [...state, action.payload],
    removeToastAction: (state, action: PayloadAction<string>) => [...state].filter(toast => toast.id !== action.payload),
  },
});

const { addToastAction, removeToastAction } = toasterSlice.actions;

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProviderContext = React.createContext<ToastProviderContextProps>(null);

export const externalToastStore = {
  addToast: (content: string, color?: string, duration?: number, reload?: boolean) => {
    // eslint-disable-next-line no-console
    console.log('ToastStore is not ready', content, color, duration, reload);
  },
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, dispatchToasts] = useReducer(toasterSlice.reducer, []);
  const removeToast = useCallback((toastId: string) => dispatchToasts(removeToastAction(toastId)), []);

  const addToast = (content: string, color = colors.green, duration = 8000, reload?: boolean) => {
    const toast: ToastProps = { id: v4(), content, color, duration, reload };
    dispatchToasts(addToastAction(toast));
    setTimeout(() => {
      removeToast(toast.id);
    }, toast.duration);
  };

  useEffect(() => {
    externalToastStore.addToast = addToast;
  }, []);

  const contextValue = useMemo(() => ({ addToast, toaster: addToast, toasts, removeToast }), [toasts]);

  return <ToastProviderContext.Provider value={contextValue}>{children}</ToastProviderContext.Provider>;
};
