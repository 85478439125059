import { type Purchase } from '../purchases.type';
import { type Locale } from '../../store/i18nSlice';

export enum QuickSearchType {
  phone = 'phone',
  cardNumber = 'card_number',
  email = 'email',
}

export enum CustomerFormType {
  create = 'create',
  update = 'update',
}

export enum CustomerType {
  customer = 'customer',
  internal = 'internal',
}

export interface CustomerInput {
  lastname?: string;
  firstname?: string;
  companyName?: string;
  personType?: PersonTypes;
  companyType?: CompanyType;
  email?: string;
  phone?: string;
  cardNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
  gender?: number;
  optIn?: boolean;
  quickSearchType?: QuickSearchType;
}

export interface CustomerAddress {
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
}

export interface CustomerIdentity {
  lastname: string;
  firstname: string;
  gender?: number;
  personType: PersonTypes;
  companyName?: string;
  companyType?: CompanyType;
}

export interface CustomerPhones {
  mobile: string;
  landline: string;
}

export interface Customer extends CustomerIdentity, CustomerAddress {
  email?: string;
  idPerson?: number;
  memberId: string;
  oldMemberId?: string;
  phone?: string;
  purchases?: Purchase[];
  smsAlert?: boolean;
  emailAlert?: boolean;
  language: Language | null;
  yearOfBirth?: string;
}

export interface Country {
  code: string;
  label: string;
  value: string;
  pattern: string;
}

export interface Language extends Locale {
  default?: boolean;
}

export interface ContactValue {
  item: string;
  values: string;
}

export interface CustomerContact {
  type: string;
  values: ContactValue[];
}

export interface CustomerResponse {
  parametresContact: CustomerContact[];
}

export enum Gender {
  man = 1,
  woman = 2,
}

export enum PersonTypes {
  customer = 1,
  company = 2,
  decathlon = 3,
}

export enum CompanyType {
  sportClub = 2,
  administration = 5,
  business = 6,
  association = 21,
  other = 22,
  school = 27,
}

export interface CustomerIds {
  memberId: string | null;
}

export interface CustomersLightDetails {
  memberId?: string;
  customerName: string;
  phone?: string;
}

export const initialCustomerInput: CustomerInput = {
  lastname: '',
  firstname: '',
  companyName: '',
  email: '',
  phone: '',
  cardNumber: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  city: '',
  countryCode: '',
  gender: 1,
};

export const companyTypes = Object.keys(CompanyType).filter(key => isNaN(Number(key)));
